import moment from "moment-timezone";
import React from "react";
// import { TimePicker } from "sassy-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addHours, isAfter, isBefore, setHours, setMinutes } from "date-fns";

class JobConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingUserDetails: props.bookingUserDetails,
      gEvent: null,
      isSubmit: false,
      errorField: {},
      isError: false,
      chooseTime: false,
      selectedTime: addHours(new Date(), 1.5),
      isTimeError: false,
      timeErrorMessage: null,
      isMobile: window.innerWidth <= 768,
    };
    this.handleBookingInputs = this.handleBookingInputs.bind(this);
    this.handleBlurInputs = this.handleBlurInputs.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.selectPickupTime = this.selectPickupTime.bind(this);
    this.checkUserPickupTime = this.checkUserPickupTime.bind(this);
  }

  componentDidMount() {
    const now = new Date();
    const later = new Date(now.getTime() + 1.5 * 60 * 60 * 1000);

    // Set the state to trigger a re-render with the new value
    // this.setState({ selectedTime: later });
    window.addEventListener("resize", this.handleResize);
  }

  validate = (values) => {
    const errors = {};
    // const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!values.hasOwnProperty("firstName") || !values.firstName) {
      errors.firstName = "This field is required";
    }
    if (!values.hasOwnProperty("lastName") || !values.lastName) {
      errors.lastName = "This field is required";
    }
    if (!values.hasOwnProperty("email") || !values.email) {
      errors.email = "This field is required";
    }
    if (!values.hasOwnProperty("phone") || !values.phone) {
      errors.phone = "This field is required";
    }
    return errors;
  };

  handleBookingInputs(val) {
    clearTimeout(this.state.gEvent);
    val = val.target;
    this.setState((prevState) => {
      let bookingUserDetails = Object.assign({}, prevState.bookingUserDetails); // creating copy of state variable jasper
      bookingUserDetails[val.name] = val.value; // update the name property, assign a new value
      return { bookingUserDetails };
    });
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/.test(email)) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  handleBlurInputs() {
    // this.props.handleBookingUserDetails(this.state.bookingUserDetails);
  }

  async handleSubmit() {
    this.setState({
      errorField: this.validate(this.state.bookingUserDetails),
    });
    if (this.validateEmail(this.state.bookingUserDetails.email) == false) {
      return;
    }
    if (this.state.isTimeError) {
      return;
    }

    try {
      let userDetails = this.state.bookingUserDetails;
      if (userDetails) {
        this.confirmTheJob(this.state);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  }

  selectPickupTime = (time) => {
    console.log("location details: ", this.props.location);
    const locationTimes = this.props.location.openHoursDisplay;
    let dayText = moment().format("ddd");
    console.log("dayText: ", dayText);

    let locationStart = "08:00";
    let locationEnd = "18:00";

    if (dayText == "Mon" || dayText == "Tue" || dayText == "Wed") {
      let val = locationTimes["Mon-Wed"];
      // locationStart = val.split("-")[0];
      // locationEnd = val.split("-")[1];
    } else {
      let val = locationTimes[dayText];
      // locationStart = val.split("-")[0];
      // locationEnd = val.split("-")[1];
    }

    console.log("locationStart: ", locationStart);
    console.log("locationEnd: ", locationEnd);

    const startTime = moment(locationStart, "HH:mmA").format("HH:mm");
    const endTime = moment(locationEnd, "HH:mmA").format("HH:mm");

    console.log("Start Time: ", startTime);
    console.log("End Time: ", endTime);

    console.log(
      "select pickup time from selector: ",
      moment(time).format("HH")
    );
    console.log(
      "select pickup time from selector: ",
      moment(time).format("mm")
    );
    var minTime = moment().add(1, "hours").add(30, "minutes");
    minTime = minTime.format("HH:mm");
    // console.log("minTime: ", minTime);
    var selectedTime = moment(time).format("HH:mm");
    var selectedTimeDB = moment(time).format("YYYY-MM-DD HH:mm:ss");
    console.log("selected time: ", selectedTime);

    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");
    const isBetween = moment(selectedTime, "HH:mm").isBetween(
      startMoment,
      endMoment
    );

    if (!isBetween) {
      if (this.state.isTimeError == false || this.state.selectedTime !== time) {
        console.log(" time: ", time);
        this.setState({
          isTimeError: true,
          selectedTime: time,
          timeErrorMessage: `Cannot select time outside of open hours, please revise selected time.`,
        });
        this.props.handleSelectPickupTime(selectedTimeDB);
      }
      return;
    }

    if (this.state.selectedTime !== time) {
      if (moment(minTime, "HH:mm").isAfter(moment(selectedTime, "HH:mm"))) {
        this.setState({
          isTimeError: true,
          selectedTime: time,
          timeErrorMessage: `Your pickup time must be 1.5 hours from now.`,
        });
      } else {
        this.setState({
          isTimeError: false,
          selectedTime: time,
        });
      }
      this.props.handleSelectPickupTime(selectedTimeDB);
    }
  };

  checkUserPickupTime(inp) {
    console.log(inp.target.value);
    this.setState({
      chooseTime: inp.target.value,
    });

    if (inp.target.value == "false") {
      this.setState({
        isTimeError: false,
      });
    }
  }

  filterPassedTime = (time) => {
    const startLimit = addHours(new Date(), 1.5);
    const endLimit = setHours(setMinutes(new Date(), 0), 18); // 6 pm

    return isAfter(time, startLimit) && isBefore(time, endLimit);
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  confirmTheJob = (stateValues) => {
    if (Object.keys(this.state.errorField).length === 0) {
      console.log("stateValues: ", stateValues);
      console.log("errors: ", this.state.errorField);
      console.log("this.state.isSubmit: ", this.state.isSubmit);
      console.log(
        "this.state.selectedTime: ",
        moment(this.state.selectedTime).format("YYYY-MM-DD HH:mm:ss")
      );
      this.props.confirmBooking(
        this.state.bookingUserDetails,
        this.state.chooseTime,
        moment(this.state.selectedTime).format("YYYY-MM-DD HH:mm:ss")
      );
    }
  };

  render() {
    return (
      <div className="confirmationJobStep">
        <h2 className="confirmationHeadings">About you</h2>
        <div className="aboutYouSection">
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"firstName"}
                id={"bookingFirstName"}
                placeholder={"First name"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.firstName
                }
              />
              {this.state.errorField.firstName ? (
                <p className="errorMess">{this.state.errorField.firstName}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"lastName"}
                id={"bookingLastName"}
                placeholder={"Last name"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.lastName
                }
              />
              {this.state.errorField.lastName ? (
                <p className="errorMess">{this.state.errorField.lastName}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"phone"}
                id={"bookingPhone"}
                placeholder={"Phone"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.phone
                }
              />
              {this.state.errorField.phone ? (
                <p className="errorMess">{this.state.errorField.phone}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"email"}
                name={"email"}
                id={"bookingEmail"}
                placeholder={"Email address"}
                onChange={this.handleBookingInputs}
                onBlur={this.handleBlurInputs}
                value={
                  this.state.bookingUserDetails &&
                  this.state.bookingUserDetails.email
                }
              />
              {this.state.errorField.email ? (
                <p className="errorMess">{this.state.errorField.email}</p>
              ) : null}
            </div>
          </div>
        </div>
        <h2 className="confirmationHeadings confirmJobHeading">
          Select Pickup Time
        </h2>
        {/* <p>
          Please be aware that the earliest pickup time is 1.5 hours from the
          time of booking.
        </p> */}
        <div className="flexColumn confirmSection alignCenter">
          {/* <TimePicker
            className="pickupTimeSelection"
            onChange={(val) => {
              this.selectPickupTime(val);
            }}
            value={this.state.selectedTime}
            minutesInterval={5}
          /> */}
          <DatePicker
            className="pickupTimeSelection"
            selected={this.state.selectedTime}
            onChange={this.selectPickupTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            filterTime={this.filterPassedTime}
          />
          {this.state.isTimeError && (
            <p className="errorMess">{this.state.timeErrorMessage}</p>
          )}
        </div>

        <h2 className="confirmationHeadings confirmJobHeading">
          Confirm Services Selected
        </h2>
        <div className="flexColumn confirmSection alignCenter">
          <p>
            {" "}
            <b>Service: </b>
            {this.props.allDetails.selectedService.name} | $
            {this.props.allDetails.selectedService.price}{" "}
          </p>
          {this.props.allDetails.selectedExtras.length > 0 && (
            <p>
              {" "}
              <b>Extras:</b>{" "}
              {this.props.allDetails.selectedExtras.map(function (item) {
                return (
                  <p>
                    {item.name} | ${item.price}
                  </p>
                );
              })}
            </p>
          )}
          {this.props.allDetails.selectedSubscriptions.length > 0 && (
            <p>
              {" "}
              <b>Subscriptions:</b>{" "}
              {this.props.allDetails.selectedSubscriptions.map(function (item) {
                return `${item.name} | $${item.price}/m`;
              })}
            </p>
          )}
          {this.state.chooseTime == false && (
            <p className="confirmationNote">
              {" "}
              <b>Please Note: </b>
              Pick up time for your selected service is estimated to be{" "}
              {this.props.allDetails.selectedService.type == "Car Wash"
                ? " 1 hour and 30 minutes."
                : " 2 hours."}{" "}
              Please check with an operator for the exact timing of completion.
            </p>
          )}

          <div className="buttonSection">
            <button
              className="prevButton"
              onClick={() => this.props.goBack("confirm")}
            >
              Previous Step
            </button>
            <div className="confirmationButton">
              <button
                className="lightBlueButton"
                onClick={() => this.handleSubmit()}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobConfirmation;
