import React from "react";
import * as Images from "../themes/images";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DatePicker from "sassy-datepicker";
import moment from "moment";
import { CardOption, TimeSelection } from "../components";
import { maxHeight } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3.5,
  maxWidth: window.innerWidth > 820 ? 700 : "95%",
  maxHeight: window.innerHeight > 700 ? "80%" : "90%",
  borderRadius: "50px",
  outline: 0,
};

class VehicleDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefillData: false,
      carTypeOptions: [
        {
          name: "Sedan",
          isSelected: false,
        },
        {
          name: "Wagon / SUV",
          isSelected: false,
        },
        {
          name: "4WD / 7 Seater",
          isSelected: false,
        },
      ],
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.handleModalDisplay();
  }

  changeTypeSelected(value) {
    this.setState((prevState) => ({
      carTypeOptions: prevState.carTypeOptions.map((obj) =>
        obj.name === value
          ? Object.assign(obj, { isSelected: true })
          : Object.assign(obj, { isSelected: false })
      ),
    }));
  }

  componentDidUpdate() {
    if (this.props.displayModal && this.state.prefillData == false) {
      if (this.props.carDetails) {
        var vehicleType = this.props.carDetails.body_type;
        vehicleType = vehicleType.toLowerCase();
        var selectedType = "Sedan";
        if (
          vehicleType.includes("car/station wagon") ||
          vehicleType.includes("utility")
        ) {
          selectedType = "4WD / 7 Seater";
        } else if (
          vehicleType.includes("station wagon") ||
          vehicleType.includes("dual cab")
        ) {
          selectedType = "Wagon / SUV";
        } else if (vehicleType.includes("sedan")) {
          selectedType = "Sedan";
        }

        this.changeTypeSelected(selectedType);
        this.setState({
          prefillData: true,
        });
      }
    }
  }

  continue() {
    let make = this.props.carDetails.make;
    let model = this.props.carDetails.model;
    let type = null;
    for (let vt = 0; vt < this.state.carTypeOptions.length; vt++) {
      const element = this.state.carTypeOptions[vt];
      if (element.isSelected) {
        type = element.name;
      }
    }

    if (make !== "" || model !== "" || type !== null) {
      this.props.setVehicleDetails({
        make: make,
        model: model,
        type: type,
        carRego: this.props.carDetails.plate.number,
      });
    } else {
      alert("Please make sure to");
    }
  }

  render() {
    const parentThis = this;
    return (
      <Modal
        open={this.props.displayModal}
        onClose={() => this.handleClose()}
        aria-labelledby="Vehicle Detail Modal"
        aria-describedby="Confirm vehicle details that have been retrieved."
        className="rewardModal popupModal"
      >
        <Box sx={style}>
          <div className="vehicleDetailModalContent">
            {this.props.displayModal && (
              <>
                <div>
                  <h2 className="modalTitle">
                    {this.props.title
                      ? this.props.title
                      : "Please confirm your vehicle details below are correct?"}
                  </h2>
                  <p className="modalDesc">
                    {this.props.desc
                      ? this.props.desc
                      : "You can update your vehicle details below if they are not correct."}
                  </p>
                </div>
                <div className="vehicleDetailSection">
                  <div className="flexRow">
                    <h6>Make:</h6>
                    <input
                      defaultValue={this.props.carDetails.make}
                      onChange={(val) => {
                        let value = val.target.value;
                        this.props.handleVehicleDetails("make", value);
                      }}
                    />
                  </div>
                  <div className="flexRow">
                    <h6>Model:</h6>
                    <input
                      defaultValue={this.props.carDetails.model}
                      onChange={(val) => {
                        let value = val.target.value;
                        this.props.handleVehicleDetails("model", value);
                      }}
                    />
                  </div>
                  {this.props.carDetails.year_of_manufacture !== null && (
                    <div className="flexRow">
                      <h6>Year Model:</h6>
                      <input
                        defaultValue={this.props.carDetails.year_of_manufacture}
                        onChange={(val) => {
                          let value = val.target.value;
                          this.props.handleVehicleDetails(
                            "year_of_manufacture",
                            value
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className="mt-2 flexColumn">
                    <h6>Vehicle Type:</h6>
                    <div className="flexRow">
                      {this.state.carTypeOptions.map(function (item) {
                        return (
                          <CardOption
                            item={item}
                            select={(item) =>
                              parentThis.changeTypeSelected(item)
                            }
                            key={item.name}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="buttonSection">
            <button className="lightBlueButton" onClick={() => this.continue()}>
              {" "}
              Continue{" "}
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default VehicleDetails;
