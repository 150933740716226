import { Link } from "react-router-dom";
import * as Images from "../../themes/images";
import './notFound.css';
import DocumentMeta from 'react-document-meta';

export default function NotFound() {
    document.title = '404 Page Not Found - Concierge Car Wash ';
    const meta = {
        title: '404 Page Not Found | Concierge Car Wash',
        description: "Oops, the url you entered was not found, please try again.",
    };
    return (
        <DocumentMeta {...meta}>
            <div className="notFoundPage">
                <img src={Images.ccwLogoLight} className="ccwLogo notFoundPageLogo" alt="Logo" />
                <h1 className="notFoundHeading">Oops! You seem to be lost.</h1>
                <h4 className="notFoundSubHeading">Here are some helpful links:</h4>
                <Link className="helpLinkButton" to='/'>Concierge Car Wash Booking</Link>
                {/* <Link className="helpLinkButton" to='/purchase-prepaid'>Purcahse a Prepaid Card</Link> */}
            </div>
        </DocumentMeta>
    )
}