import React from "react";
import "../style.css";

import { api, carRegoDetails } from "../../../api";
import { Button } from "@mui/material";
import moment from "moment";
import { SubscriptionItem } from "../../../components";
import Modal from "react-bootstrap/Modal";
import ExtraItem from "../../../components/ExtraItem";
import NewServiceModal from "../../../components/newServiceModal";
import Loading from "../../../components/Loading";

class ExtraSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extrasLoading: true,
      extraArray: [],
      subscriptionsLoading: true,
      subscriptionArray: [],
      locationExtras: [],
      extraServiceModalVisible: false,
      termsModalDetails: "",
      termsModalDisplay: false,
    };
    this.getAllExtra = this.getAllExtra.bind(this);
    this.handleNewServiceModal = this.handleNewServiceModal.bind(this);
    this.termsModal = this.termsModal.bind(this);
  }

  async getAllExtra() {
    console.log("location: ", this.props.location);
    let res = await api(
      `location/${this.props.location._id}/pricing?instore=true`,
      "GET",
      null
    );
    if (res.status === 200) {
      if (res.data.success) {
        console.log("Extras: ", res.data);
        this.setState({
          extraArray: res.data.extras,
          subscriptionArray: res.data.subscriptions,
          // locationExtras: res.data.locationExtras,
        });
        // if (res.data.locationExtras.length > 0) {
        //   let showModal = true;
        //   for (
        //     let index = 0;
        //     index < this.props.selectedExtrasNames.length;
        //     index++
        //   ) {
        //     const element = this.props.selectedExtrasNames[index];
        //     if (element == res.data.locationExtras[0].name) {
        //       showModal = false;
        //     }
        //   }
        //   if (showModal) {
        //     this.setState({
        //       extraServiceModalVisible: true,
        //     });
        //   }
        // }
      }
    } else {
      console.log(res);
    }
    this.setState({
      extrasLoading: false,
      subscriptionsLoading: false,
    });
  }

  handleNewServiceModal() {
    this.setState({
      extraServiceModalVisible: false,
    });
  }

  termsModal(details, show) {
    console.log("details: ", details);
    console.log("show: ", show);
    this.setState({
      termsModalDetails: details,
      termsModalDisplay: show,
    });
  }

  validateEmail(val) {
    val = val.target;
    let email = val.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    alert("You have entered an invalid email address!");

    return false;
  }

  componentDidMount() {
    this.getAllExtra();
  }

  render() {
    let parentThis = this;
    console.log("parentThis: ", parentThis);
    return (
      <div className="extraJobStep">
        <h2>Select your extras</h2>
        {this.state.extrasLoading ? (
          <Loading />
        ) : (
          this.state.extraArray.length > 0 &&
          this.state.extraArray.map(function (extra, i) {
            return (
              <ExtraItem
                index={i}
                extra={extra}
                selectExtra={() => parentThis.props.handleSelectExtra(extra)}
                selectedExtrasNames={parentThis.props.selectedExtrasNames}
              />
            );
          })
        )}
        {this.state.subscriptionArray.length > 0 && (
          <h2 className="notFirstHeading">Subscription</h2>
        )}
        {this.state.subscriptionsLoading ? (
          <Loading />
        ) : (
          this.state.subscriptionArray.length > 0 &&
          this.state.subscriptionArray.map(function (sub, i) {
            return (
              <SubscriptionItem
                key={i}
                sub={sub}
                subscriptionsChecked={parentThis.props.subscriptionsChecked}
                checkSubscription={(name) =>
                  parentThis.props.checkSubscription(name)
                }
                selectSubscription={() =>
                  parentThis.props.handleSelectSubscription(sub)
                }
                selectedSubscriptionNames={
                  parentThis.props.selectedSubscriptionNames
                }
                termsModal={(details, display) =>
                  parentThis.termsModal(details, display)
                }
              />
            );
          })
        )}
        <button
          className="prevButton"
          style={{ marginTop: 30 }}
          onClick={() => this.props.goBack("extras")}
        >
          Previous Step
        </button>
        <button
          className="button bookNowButton"
          onClick={() => this.props.goToDetails()}
        >
          Next Step
        </button>
        {this.state.extraServiceModalVisible && (
          <NewServiceModal
            service={this.state.locationExtras[0]}
            location={this.props.selectedLocationName}
            handleNewServiceModal={() => this.handleNewServiceModal()}
            addNewService={() => {
              this.selectExtra(this.state.locationExtras[0]);
              this.handleNewServiceModal();
            }}
          />
        )}
        <Modal
          show={this.state.termsModalDisplay}
          onHide={() => {
            this.setState({ termsModalDisplay: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Subscription Terms & Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {this.state.termsModalDetails &&
                this.state.termsModalDetails.length > 0 &&
                this.state.termsModalDetails.map(function (item, i) {
                  return <li key={i}>{item}</li>;
                })}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ termsModalDisplay: false });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ExtraSelect;
