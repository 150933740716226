import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../../../api";
import { CardOption, ToggleSwitch, PricingCard } from "../../../components";
import "../style.css";
import moment from "moment";
import { Button } from "@mui/material";
import stripeBookingSuccess from "../../Booking/stripeBookingSuccess";

class HasBooking extends React.Component {
  constructor(props) {
    super(props);
    this.location = this.props.location;
    this.state = {
      booking: null,
      carRego: null,
      orderedKeys: [
        "posBookingKey",
        "cart",
        "date",
        "time",
        "selectedServices",
        "selectedExtras",
      ],
    };
    this.handleCarRego = this.handleCarRego.bind(this);
    this.findBooking = this.findBooking.bind(this);
    this.orderObjectKeys = this.orderObjectKeys.bind(this);
    this.getStripeLink = this.getStripeLink.bind(this);
  }

  handleCarRego = async (rego) => {
    rego = rego.target.value;
    this.setState({
      carRego: rego,
    });
  };

  findBooking = async () => {
    var rego = this.state.carRego;
    var location = this.location;
    var date = moment().format("YYYY-MM-DD");

    try {
      const booking = await api(`booking/find`, "POST", {
        carRego: rego,
        location: location,
        date: date,
      });
      console.log("found booking: ", booking);
      if (booking.status == 200) {
        if (booking.data.success) {
          let foundBooking = this.orderObjectKeys(
            booking.data.booking,
            this.state.orderedKeys
          );
          this.setState({
            booking: foundBooking,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
    }
  };

  orderObjectKeys(object, keyOrder) {
    const orderedObject = {};

    // Iterate over the keyOrder array
    for (const key of keyOrder) {
      if (object.hasOwnProperty(key)) {
        // Transfer the value from the original object to the new object
        orderedObject[key] = object[key];
      } else {
        // Handle keys that are not present in the original object (optional)
        orderedObject[key] = null; // Or any default value you prefer
      }
    }

    return orderedObject;
  }

  getStripeLink = async () => {
    try {
      const stripeLink = await api("booking/job/pay", "POST", {
        booking: this.state.booking,
        urlpath: window.location.search,
      });
      if (stripeLink.status == 200) {
        if (stripeLink.data.success) {
          window.location.href = stripeLink.data.stripeURL;
        } else {
          alert(stripeBookingSuccess.data.message);
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
    }
  };

  render() {
    return (
      <div className="hasBookingPage">
        {this.state.booking == null ? (
          <div>
            <h3>Please enter your registration number to find your booking</h3>
            <input
              className="jobInput"
              type={"text"}
              name={"carRego"}
              placeholder={"Enter rego..."}
              id={"carRego"}
              onChange={this.handleCarRego}
              value={this.state.carRego}
            />
            <Button
              onClick={() => this.findBooking()}
              title="Retrieve Booking"
              color="primary"
              disabled={
                this.state.carRego == null || this.state.carRego == ""
                  ? true
                  : false
              }
            >
              <span>Retrieve Booking</span>
            </Button>
          </div>
        ) : (
          <div>
            <div>
              <h3>Confirm booking details</h3>
              <div className="bookingDetailsSection">
                {Object.keys(this.state.booking).map((key) => {
                  const value = this.state.booking[key];
                  switch (key) {
                    case "posBookingKey":
                      return (
                        <div className="flexRow">
                          <h6>Booking Key:</h6>
                          <p>{value}</p>
                        </div>
                      );
                    case "selectedExtras":
                      if (value.length > 0) {
                        return (
                          <div className="flexRow">
                            <h6>Extras</h6>
                            {value.map((extra) => {
                              return (
                                <div>
                                  <p>{extra}</p>
                                </div>
                              );
                            })}
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    case "selectedServices":
                      return (
                        <div className="flexRow">
                          <h6>Services</h6>
                          {value.map((service) => {
                            return (
                              <div>
                                <p>
                                  {service.name} x{service.quantity} - Price: $
                                  {service.servicePrice}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      );
                    case "time":
                      return (
                        <div className="flexRow">
                          <h6>Time:</h6>
                          <p>
                            {value.hour}: {value.minute} {value.ampm}
                          </p>
                        </div>
                      );
                    case "date":
                      return (
                        <div className="flexRow">
                          <h6>Date:</h6>
                          <p>{value}</p>
                        </div>
                      );
                    case "cart":
                      return (
                        <div className="flexRow">
                          <h6>Location:</h6>
                          <p>{value.sessionData.selectedLocationName}</p>
                        </div>
                      );
                    default:
                      break;
                  }

                  return <div></div>;
                })}
              </div>
            </div>
            <div className="buttonRow">
              <Button
                onClick={() =>
                  this.setState({
                    booking: null,
                    carRego: null,
                  })
                }
                title="Re-Enter Car Rego"
                color="secondary"
              >
                <span>Re-Enter Car Rego</span>
              </Button>
              {this.state.booking && (
                <Button
                  onClick={() => this.getStripeLink()}
                  title="Pay"
                  color="primary"
                >
                  <span>Pay</span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HasBooking;
