import React from "react";
import * as Images from "../themes/images";

class CardOption extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isSelected: false,
        }
        this.selectService = this.selectService.bind(this);
    }

    componentDidMount(){
        if(this.props.item.isSelected){
            this.setState({
                isSelected: true
            })
        }
    }

    selectService(){
        this.props.select(this.props.item.name);
    }

    // return(
    //     
    // );
    
    
    render() {
        let image = null;
        if(this.props.item.name === "Sedan"){
            image = Images.sedanIco;
        }
        if(this.props.item.name === "Wagon / SUV"){
            image = Images.wagonIco;
        }
        if(this.props.item.name === "4WD / 7 Seater"){
            image = Images.fourWDIco;
        }
        if(this.props.item.name === "Car Wash"){
            image = Images.carWashIco;
        }
        if(this.props.item.name === "Wash & Detail"){
            image = Images.washDetailIco;
        }
        return(
            <div className={this.props.item.isSelected ? "activeCard " : "cardOption " } onClick={() => this.props.select(this.props.item.name)}>
                <img src={image} className="cardOptionImage" alt="Card Img" />
                <p className="cardOptionTitle">{this.props.item.name}</p>
            </div>
        )
        
    }
}

export default CardOption;