import React from "react";
import "../style.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api, carRegoDetails } from "../../../api";
import { CardOption, BookingPricingCard } from "../../../components";
import Loading from "../../../components/Loading";
import { Button } from "@mui/material";
import VehicleDetails from "../../../components/vehicleDetailsModal";
import moment from "moment";
import NewPricingCard from "../../../components/newPricingCard";

class ServicesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedService: "",
      selectedParentService: "Car Wash",
      serviceOptions: [
        {
          name: "Car Wash",
          isSelected: true,
        },
        {
          name: "Wash & Detail",
          isSelected: false,
        },
      ],
      locationPricing: [],
      loading: true,
      width: 0,
    };
    this.changeServiceSelected = this.changeServiceSelected.bind(this);
    this.selectServiceType = this.selectServiceType.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.getLocationPricing = this.getLocationPricing.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.getLocationPricing();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  async getLocationPricing() {
    const pricing = await api(
      `location/${this.props.location._id}/pricing?instore=true`,
      "GET",
      null
    );
    console.log("pricing: ", pricing);
    if (pricing.status == 200) {
      var resp = pricing.data;
      if (resp.success) {
        // console.log("loc pricing", resp.pricing);
        this.setState({
          locationPricing: resp.pricing,
        });
      } else {
        alert(resp.message);
      }
    }
    this.setState({
      loading: false,
    });
  }

  changeServiceSelected(value) {
    this.setState({
      selectedParentService: value,
    });
    this.setState((prevState) => ({
      serviceOptions: prevState.serviceOptions.map((obj) =>
        obj.name === value
          ? Object.assign(obj, { isSelected: true })
          : Object.assign(obj, { isSelected: false })
      ),
    }));
  }

  selectServiceType(item, carType) {
    var posId = 0;
    this.setState({
      selectedService: item,
    });

    // console.log("carType, ", carType);

    let type = "first";

    switch (carType) {
      case "Sedan":
        type = "first";
        break;
      case "Wagon / SUV":
        type = "second";
        break;
      case "4WD / 7 Seater":
        type = "third";
        break;
      default:
        type = "first";
        break;
    }

    var servicePrice = 0;

    if (item[type] == "POA") {
      alert(
        "Cannot Complete Job with selected service, please speak to one of our opperators if this is the service you would like."
      );
      return;
    } else {
      console.log(item[type].value);
      servicePrice = item[type].value;
    }

    this.props.handleSelectServiceType(
      item.name,
      this.state.selectedParentService,
      item,
      type,
      servicePrice,
      posId,
      carType
    );
  }

  render() {
    let parentThis = this;
    const pricingOrder = [
      "Hand Polish",
      "Interior Detail",
      "Full Detail",
      "Paint Protection",
      "Express Wash",
      "Economy Wash",
      "Premium Wash",
      "Super Wash",
    ];
    const sortByObject = pricingOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    let pricingOptions = [];
    if (this.state.locationPricing) {
      pricingOptions = this.state.locationPricing.sort(
        (a, b) => sortByObject[a.name] - sortByObject[b.name]
      );
    }
    return (
      <div className="serviceStep">
        <h3>Select your service type</h3>
        {this.state.loading ? (
          <Loading opacity={1} />
        ) : (
          <>
            {this.state.width <= 767 && (
              <p style={{ marginTop: 10 }}>
                Services are listed below, please scroll to select your service.
              </p>
            )}
            <div className="bookingPriceGrid">
              {pricingOptions.map(function (item, i) {
                console.log("Pricing map item", item);
                var selectedType = parentThis.props.vehicleDetails.type;
                return (
                  <NewPricingCard
                    key={i}
                    item={item}
                    selectedType={selectedType}
                    selectServiceType={() =>
                      parentThis.selectServiceType(item, selectedType)
                    }
                    selectedService={parentThis.state.selectedService}
                    bundleSelected={null}
                    pricingTemplate={parentThis.props.location.pricingTemplate}
                    jobCard={true}
                    select={(item) =>
                      parentThis.selectServiceType(item, selectedType)
                    }
                  />
                );
              })}
            </div>
            <div className="buttonSection">
              <button
                className="prevButton"
                onClick={() => this.props.goBack("services")}
              >
                Previous Step
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ServicesSelect;
