import React from "react";
import { api, googleEvent } from "../../../api";
import "../style.css";
import * as Images from "../../../themes/images";
import { instanceOf } from "prop-types";
import HeaderMobile from "../../../components/headerMobile";
import { withCookies, Cookies } from "react-cookie";
import Loading from "../../../components/Loading";
import ReactPixel from "react-facebook-pixel";

class JobSuccess extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      subscriptions: [],
      hasSubscription: false,
      successSub: false,
      bookingNumber: false,
      error: false,
      errorMessage: "",
      loading: true,
      subscriptionActive: false,
      subLoading: false,
      returnURL: "",
    };
    this.confirmJob = this.confirmJob.bind(this);
    this.updateUserSubscription = this.updateUserSubscription.bind(this);
    this.submitConfirmJob = this.submitConfirmJob.bind(this);
  }

  async confirmJob() {
    const queryParams = new URLSearchParams(window.location.search);
    const bookingKey = queryParams.get("bookingKey");
    const session_id = queryParams.get("session_id");
    const hasSubscription = queryParams.get("choseSubscription");
    const returnURL = queryParams.get("returnURL");

    this.setState({
      returnURL: returnURL,
    });

    let data = {
      bookingKey: bookingKey,
      session_id: session_id,
    };
    if (bookingKey) {
      console.log("hasSubscription; ", hasSubscription);
      if (hasSubscription == "true") {
        this.setState({
          hasSubscription: true,
          subLoading: true,
        });
        let sub = await this.createSubCard(data);
        console.log("sub: ", sub);
        await this.submitConfirmJob(data);
      } else {
        await this.submitConfirmJob(data);
      }
    } else {
      this.setState({
        error: true,
        errorMessage: "You can only access this page from stripe.",
        loading: false,
      });
    }
  }

  async submitConfirmJob(data) {
    let res = await api("booking/job/confirmed", "POST", data);
    if (res.status == 200) {
      if (res.data.success) {
        //   this.props.fbEvent("Purchase", {
        //     currency: "AUD",
        //     value: Number(res.data.booking.total),
        //   });
        // googleEvent(this.props.ReactGA, this.props.GTM, "booking_confirmed", {
        //   bookingNumber: res.data.bookingNumber,
        // });

        this.setState({
          bookingNumber: res.data.bookingNumber,
          loading: false,
        });
      } else {
        this.setState({
          error: true,
          errorMessage: res.data.message,
          loading: false,
        });
      }
    }
  }

  async createSubCard(data) {
    console.log("create sub card: ", data);
    let res = await api("booking/subscriptioncard/create", "POST", data);
    if (res.status == 200) {
      if (res.data.success) {
        var subs = [];
        for (let sc = 0; sc < res.data.cardIds.length; sc++) {
          const element = res.data.cardIds[sc];
          subs.push(element);
          // googleEvent(
          //   this.props.ReactGA,
          //   this.props.GTM,
          //   "subscription_card_created",
          //   { subscriptionId: element }
          // );
        }

        if (res.data.cardIds.length > 0) {
          this.setState({
            subscriptionActive: true,
            subscriptions: subs,
            subLoading: false,
          });
          return res.data.cardIds;
        }
      }
    }
  }

  async updateUserSubscription(selectedSubs, session_id) {
    const { cookies } = this.props;
    let bookingKey = cookies.get("bookingNumber");
    let data = {
      bookingKey: bookingKey,
      subscriptions: selectedSubs,
      session_id: session_id,
    };

    let res = await api("customer/subscription/create", "POST", data);
    if (res.status == 200) {
      let resp = res.data;
      if (resp.success) {
        cookies.remove("selectedSubs", { path: "/", maxAge: 86400 });
        // googleEvent(
        //   this.props.ReactGA,
        //   this.props.GTM,
        //   "subscription_added_customer",
        //   { subscriptionId: resp.data.stripeId }
        // );
      } else {
        alert(resp.message);
      }
    }
  }

  setupBeforeUnloadListener = () => {
    const parentState = this;
    window.addEventListener("beforeunload", (ev) => {
      if (parentState.state.loading) {
        ev.preventDefault();
        return (ev.returnValue =
          "Are you sure you want to close? Your booking has not been processed yet and you may forfeit your $2 booking fee.");
      } else {
        return;
      }
    });
  };

  componentDidMount() {
    document.title = "Booking Job Success - Concierge Car Wash";
    const { cookies } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    ReactPixel.pageView();
    this.confirmJob();

    this.setupBeforeUnloadListener();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="bookingSuccessPage">
          {window.innerWidth < 767 && <HeaderMobile />}
          <div className="bookingBanner">
            <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
          </div>
          <div className="watingMessage">
            <h1>
              We are processing your job, please do not leave this page until
              you recieve your job reference number.
            </h1>
          </div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="bookingSuccessPage">
        {window.innerWidth < 767 && <HeaderMobile />}
        <div className="bookingBanner">
          <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
        </div>
        {this.state.error ? (
          <div className="failContent">
            <h1>{this.state.errorMessage}</h1>
            <div className="backToWebsite">
              <a
                className="lightBlueButton"
                href={"https://conciergecarwash.com.au"}
              >
                {" "}
                Continue{" "}
              </a>
            </div>
          </div>
        ) : (
          <div className="successContent">
            <img
              src={Images.blueTick}
              className="successTick"
              alt="success tick"
            />
            <h1>
              {this.state.successSub
                ? "You successfully subscribed"
                : "You successfully booked in your job."}
            </h1>
            {this.state.bookingNumber !== false && (
              <>
                <h3 className="bookingNumber">
                  Booking # {this.state.bookingNumber}
                </h3>
                <p className="note">
                  Thank you for choosing Concierge Car Wash. Please proceed to
                  an empty Concierge Car Wash bay and hand your keys over to the
                  operator. Please refer to your SMS receipt to collect your
                  keys and make payment.
                </p>
              </>
            )}
            <div className="backToWebsite">
              <a
                className="lightBlueButton"
                href={
                  // this.state.returnURL !== null
                  //   ? "/" + this.state.returnURL
                  //   : "/"
                  "https://conciergecarwash.com.au"
                }
              >
                {" "}
                Finish{" "}
              </a>
            </div>
            {this.state.hasSubscription && this.state.subLoading && (
              <div style={{ marginTop: 50 }}>
                <h4>Your Subscriptions are being created, please wait...</h4>
                <Loading />
              </div>
            )}
            {this.state.subscriptionActive && (
              <>
                <h2 className="subsctriptionHeading">
                  Your purchased subscriptions:{" "}
                </h2>
                <div className="listedSubs">
                  <ul>
                    {this.state.subscriptions.map(function (sub) {
                      return <li className={sub}>{sub}</li>;
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withCookies(JobSuccess);
