import React from "react";
import { greyClockIcon } from "../themes/images";
class NewPricingCard extends React.Component {
  render() {
    let type = this.props.selectedType;
    type = type.toLowerCase().split(" / ")[0].trim();
    let includeText = "";
    let isSelected = false;

    if (this.props.selectedService._id == this.props.item._id) {
      isSelected = true;
    }

    // console.log("item : ", this.props.item.name);

    // console.log("sale price: ", salePrice);
    switch (this.props.item.name.toLowerCase().trim()) {
      case "express wash":
        includeText = "What's included";
        break;
      case "economy wash":
        includeText = "Express wash plus";
        break;
      case "premium wash":
        includeText = "Economy wash plus";
        break;
      case "super wash":
        includeText = "Premium wash plus";
        break;
      default:
        includeText = "Super wash plus";
        break;
    }

    let price = this.props.item;

    price[type] = price[type].value ? price[type].value : price[type];

    let checkType = "first";

    switch (this.props.carType) {
      case "Sedan":
        checkType = "first";
        break;
      case "Wagon / SUV":
        checkType = "second";
        break;
      case "4WD / 7 Seater":
        checkType = "third";
        break;
      default:
        checkType = "first";
        break;
    }

    if (price[checkType]["unavailable"]) {
      return null;
    } else {
      if (price[type].value == 0) {
        return null;
      } else {
        return (
          <div
            className={
              "pricingCard" +
              (this.props.item.name.toLowerCase().trim() == "hand polish"
                ? " mostPopular"
                : "") +
              (isSelected ? " selectedService" : "")
            }
          >
            <h2 className="pricingCardHeading">{this.props.item.name}</h2>
            {<h2 className={"pricingCardText"}>${price[type]}</h2>}
            <div
              className={
                isSelected
                  ? "bookNowButton"
                  : "blueBorderButton bookingPriceSelect"
              }
              onClick={() => this.props.selectServiceType(this.props.item._id)}
            >
              {isSelected ? "Selected" : "Choose"}
            </div>
            <div className="serviceDurationSection">
              <img className="durationIcon" src={greyClockIcon} alt="clock" />
              <p className="serviceDuration">
                {" From " + this.props.item.duration + "min"}
              </p>
            </div>
            <p className="smallDesc">{this.props.item.description}</p>
            <p className="includedText lightBlueText">{includeText}</p>
            <ul className="includedItemsList">
              {this.props.item.includedOptions.map(function (listItem, i) {
                return (
                  <li key={i} className="includedItem">
                    {listItem}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      }
    }
    // return <></>;
  }
}

export default NewPricingCard;
