import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../../api";
import {
  CardOption,
  ToggleSwitch,
  PricingCard,
  Extras,
} from "../../components";
import "./style.css";
import HasBooking from "./hasBooking";
import NoBooking from "./noBooking";
import ServicesSelect from "./servicesSelect";
import ExtraSelect from "./extraSelect";
import "../Booking/booking.css";
import JobConfirmation from "./confirmation";

class JobBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBooking: false,
      location: null,
      allLocations: [],
      locationArray: [],
      vehicleDetails: null,
      selectedService: null,
      selectedExtras: [],
      selectedExtrasNames: [],
      selectedSubscriptions: [],
      selectedSubscriptionNames: [],
      subscriptionsChecked: [],
      confirmationScreen: false,
      selectedPickupTime: null,
    };
    this.toggleView = this.toggleView.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.updateVehicleDetails = this.updateVehicleDetails.bind(this);
    this.handleSelectServiceType = this.handleSelectServiceType.bind(this);
    this.handleSelectExtra = this.handleSelectExtra.bind(this);
    this.handleSelectSubscription = this.handleSelectSubscription.bind(this);
    this.handleSelectPickupTime = this.handleSelectPickupTime.bind(this);
    this.checkSubscription = this.checkSubscription.bind(this);
    this.confirmBooking = this.confirmBooking.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  toggleView = async () => {
    this.setState({
      hasBooking: !this.state.hasBooking,
    });
  };

  getLocation = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const location = queryParams.get("location");
    if (location) {
      var locationDetails = await api(
        `location/pricing/${location}`,
        "GET",
        null
      );
      if (locationDetails.status == 200) {
        if (locationDetails.data.success) {
          this.setState({
            location: locationDetails.data.location,
          });
          console.log("location retrieved: ", locationDetails.data.location);
        }
      }
    } else {
      let res = await api("location/pricing", "GET", null);
      if (res.status === 200) {
        if (res.data.success) {
          let locations = res.data.pricing.map(function (item, index) {
            return { label: item.seoName, id: item._id };
          });
          this.setState({
            allLocations: locations,
            locationArray: res.data.pricing,
          });
        }
      } else {
        console.log(res);
      }
    }
  };

  handleSelectedLocation(value) {
    if (value) {
      let locationid = value.id;
      let locations = this.state.locationArray;
      for (let index = 0; index < locations.length; index++) {
        const element = locations[index];
        if (element._id === locationid) {
          this.setState({
            location: element,
          });
          break;
        }
      }
    } else {
      this.setState({
        location: null,
      });
    }
  }

  handleSelectServiceType(name, serviceType, value, type, price, posId) {
    console.log("selected service: ", name);
    console.log("selected serviceType: ", serviceType);
    console.log("selected value: ", value);
    console.log("selected type: ", type);
    console.log("selected price: ", value[type].value);
    console.log("selected posId: ", value[type].posKey);
    this.setState({
      selectedService: {
        name: name,
        id: value._id,
        type: serviceType,
        price: value[type].value,
        posId: value[type].posKey,
      },
    });
  }

  handleSelectExtra(extra) {
    let selectedExtras = this.state.selectedExtras;
    let selectedExtrasNames = this.state.selectedExtrasNames;
    if (selectedExtrasNames.includes(extra.name)) {
      const index = selectedExtrasNames.indexOf(extra.name);
      if (index > -1) {
        selectedExtrasNames.splice(index, 1);
        var secondIndex = selectedExtras.findIndex(function (o) {
          return o.name === extra.name;
        });
        if (secondIndex !== -1) selectedExtras.splice(secondIndex, 1);
      }
    } else {
      selectedExtrasNames.push(extra.name);
      selectedExtras.push(extra);
    }
    this.setState({
      selectedExtras: selectedExtras,
      selectedExtrasNames: selectedExtrasNames,
    });
  }

  handleSelectSubscription(sub) {
    let selectedSubscriptions = this.state.selectedSubscriptions;
    let selectedSubscriptionNames = this.state.selectedSubscriptionNames;
    let checkedSubs = this.state.subscriptionsChecked;
    if (selectedSubscriptionNames.includes(sub.name)) {
      const index = selectedSubscriptionNames.indexOf(sub.name);
      if (index > -1) {
        selectedSubscriptionNames.splice(index, 1);
        var secondIndex = selectedSubscriptions.findIndex(function (o) {
          return o.name === sub.name;
        });
        if (secondIndex !== -1) selectedSubscriptions.splice(secondIndex, 1);
      }
    } else {
      selectedSubscriptionNames.push(sub.name);
      selectedSubscriptions.push(sub);
    }
    this.setState({
      selectedSubscriptions: selectedSubscriptions,
      selectedSubscriptionNames: selectedSubscriptionNames,
    });
    if (checkedSubs.includes(sub.name)) {
      const index = checkedSubs.indexOf(sub.name);
      if (index > -1) {
        checkedSubs.splice(index, 1);
      }
    }
    this.setState({
      subscriptionsChecked: checkedSubs,
    });
  }

  handleSelectPickupTime(time) {
    console.log("time: ", time);
  }

  checkSubscription(name) {
    let subscriptionsChecked = this.state.subscriptionsChecked;

    if (subscriptionsChecked.includes(name)) {
      const index = subscriptionsChecked.indexOf(name);
      if (index > -1) {
        subscriptionsChecked.splice(index, 1);
      }
    } else {
      subscriptionsChecked.push(name);
    }

    this.setState({
      subscriptionsChecked: subscriptionsChecked,
    });
  }

  updateVehicleDetails(details) {
    this.setState({
      vehicleDetails: details,
    });
  }

  goBack(currentStep) {
    switch (currentStep) {
      case "confirm":
        this.setState({
          confirmationScreen: false,
        });
        break;
      case "extras":
        this.setState({
          selectedService: null,
        });
        break;
      case "services":
        this.setState({
          vehicleDetails: null,
        });
        break;
      case "location":
        this.setState({
          location: null,
        });
        break;
      default:
        break;
    }
  }

  async confirmBooking(userDetails, choseTime, selectedTime) {
    console.log("confirm booking now: ", userDetails);
    console.log("selected service: ", this.state.selectedService);
    console.log("selected extra: ", this.state.selectedExtras);
    console.log("selected subscription: ", this.state.selectedSubscriptions);
    console.log("chose time: ", choseTime);
    console.log("selected time: ", selectedTime);

    console.log("entered rego: ", this.state.vehicleDetails);
    console.log("selected subscriptions: ", this.state.selectedSubscriptions);

    const data = {
      customer: userDetails,
      service: this.state.selectedService,
      extras: this.state.selectedExtras,
      subs: this.state.selectedSubscriptions,
      location: this.state.location,
      vehicleDetails: this.state.vehicleDetails,
      urlpath: window.location.search,
      pickupTime: selectedTime,
    };

    // console.log("data: ", data);

    try {
      const confirmJob = await api("booking/job/create", "POST", data);
      if (confirmJob.status == 200) {
        if (confirmJob.data.success) {
          window.location.href = confirmJob.data.successfullJob;
          this.setState({
            hasBooking: false,
            location: null,
            allLocations: [],
            locationArray: [],
            vehicleDetails: null,
            selectedService: null,
            selectedExtras: [],
            selectedExtrasNames: [],
            selectedSubscriptions: [],
            selectedSubscriptionNames: [],
            subscriptionsChecked: [],
            confirmationScreen: false,
          });
        } else {
          alert(confirmJob.data.message);
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
    }
  }

  componentDidMount() {
    console.log("did component update?");
    this.getLocation();
  }

  render() {
    return (
      <div className="detectBookingPage">
        <div className="detectBookingSection">
          <h1>
            {this.state.location == null
              ? "Select a location to continue..."
              : // : "Do you have a booking?"}
                "Start your booking"}
          </h1>
          {this.state.location == null ? (
            <div>
              <div className="inputArea">
                <Autocomplete
                  disablePortal
                  id="locationsInput"
                  options={this.state.allLocations}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a location" />
                  )}
                  onChange={(event, newValue) => {
                    this.handleSelectedLocation(newValue);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="optionBox">
              <div className="toggleSection">
                {/* <div
                  className={`hasBooking darkBorder ${
                    this.state.hasBooking && "active"
                  }`}
                  onClick={() => this.toggleView()}
                >
                  <p>Have a booking</p>
                </div> */}
                {/* <div
                  className={`noBooking darkBorder ${
                    !this.state.hasBooking && "active"
                  }`}
                  onClick={() => this.toggleView()}
                >
                  <p>Don't have a booking</p>
                </div> */}
              </div>
              {this.state.hasBooking ? (
                <HasBooking location={this.state.location} />
              ) : this.state.vehicleDetails == null ? (
                <NoBooking
                  location={this.state.location}
                  updateVehicleDetails={this.updateVehicleDetails}
                  goBack={this.goBack}
                />
              ) : this.state.selectedService == null ? (
                <ServicesSelect
                  vehicleDetails={this.state.vehicleDetails}
                  location={this.state.location}
                  handleSelectServiceType={this.handleSelectServiceType}
                  goBack={this.goBack}
                />
              ) : this.state.confirmationScreen == false ? (
                <ExtraSelect
                  location={this.state.location}
                  handleSelectExtra={this.handleSelectExtra}
                  handleSelectSubscription={this.handleSelectSubscription}
                  selectedExtras={this.state.selectedExtras}
                  selectedExtrasNames={this.state.selectedExtrasNames}
                  selectedSubscriptions={this.state.selectedSubscriptions}
                  selectedSubscriptionNames={
                    this.state.selectedSubscriptionNames
                  }
                  checkSubscription={this.checkSubscription}
                  subscriptionsChecked={this.state.subscriptionsChecked}
                  goToDetails={() => {
                    if (
                      this.state.selectedSubscriptionNames.length !==
                      this.state.subscriptionsChecked.length
                    ) {
                      return;
                    }
                    this.setState({
                      confirmationScreen: true,
                    });
                  }}
                  goBack={this.goBack}
                />
              ) : (
                <JobConfirmation
                  allDetails={{
                    selectedService: this.state.selectedService,
                    selectedExtras: this.state.selectedExtras,
                    selectedSubscriptions: this.state.selectedSubscriptions,
                    selectedPickupTime: this.state.selectedPickupTime,
                  }}
                  confirmBooking={this.confirmBooking}
                  handleSelectPickupTime={(val) =>
                    this.handleSelectPickupTime(val)
                  }
                  goBack={this.goBack}
                  location={this.state.location}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default JobBooking;
