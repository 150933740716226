import axios from "axios";
export const api = async function (endpoint, type, data) {
  const origin = "https://api.conciergecarwash.com.au/v1/";
  // const origin = "http://localhost:8090/v1/";
  let request = await axios({
    method: type,
    maxBodyLength: Infinity,
    url: origin + endpoint,
    data: data,
  });
  return request;
};

export const carRegoDetails = async function (state, rego) {
  console.log("state: ", state);
  console.log("rego: ", rego);
  var query = {
    query: `query {nevdisPlateSearch_v2(plate: "${rego}", state: ${state}) {\n    vin\n    plate {\n      number\n      state\n    }\n    make\n    model\n    blueflag_make\n    blueflag_model\n    engine_number\n    vehicle_type\n    body_type\n    colour\n    compliance_plate\n    year_of_manufacture\n    registration {\n      status\n      expiry_date\n    }\n    }\n  }`,
    variables: {},
  };

  var url = "https://ubuxgyols2.execute-api.ap-southeast-2.amazonaws.com/prod/";
  var headers = {
    Authorization: `JWT ${process.env.REACT_APP_AWS_JWT}`,
  };

  let request = await axios({
    method: "POST",
    headers: headers,
    url: url,
    data: query,
  });
  return request;
};

export const googleEvent = async function (ReactGA, GTM, event, value) {
  if (event == "booking_confirmed") {
    const booking = await api(`booking/${value.bookingNumber}`, "GET", null);
    console.log("get booking: ", booking);
    if (booking.status == 200) {
      let data = booking.data.booking;
      let items = [];
      if (data.cart !== null) {
        for (
          let i = 0;
          i < data.cart.sessionData.selectedServiceId.length;
          i++
        ) {
          const element = data.cart.sessionData.selectedServiceId[i];
          let obj = {
            item_name: element.name,
            item_id: element.id,
            quantity: element.quantity,
          };
          items.push(obj);
        }
      } else {
        for (let i = 0; i < data.selectedServices.length; i++) {
          const element = data.selectedServices[i];
          let obj = {
            item_name: element.name,
            item_id: element.id,
            quantity: element.quantity,
          };
          items.push(obj);
        }
      }
      GTM.dataLayer({
        dataLayer: {
          event: event,
          eventProps: {
            value: value,
          },
        },
      });
      GTM.dataLayer({
        dataLayer: {
          event: "ecommerce",
          ecommerce: {
            purchase: {
              currency: "AUD",
              value: data.cart.sessionData.estimatedTotal,
              discount: data.cart.sessionData.discount,
              coupon: data.cart.sessionData.couponCode,
              reward: data.cart.sessionData.rewardCode,
              offer: data.cart.sessionData.offerCode,
              transaction_id: value.bookingNumber,
              items: items,
            },
          },
        },
      });
      ReactGA.event(event, value);
    }
  } else {
    ReactGA.event(event, value);
  }
};
