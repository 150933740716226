import React from "react";
import { api, googleEvent } from "../../api";
import './booking.css';
import * as Images from "../../themes/images";
import { instanceOf } from 'prop-types';
import HeaderMobile from "../../components/headerMobile"
import { withCookies, Cookies } from 'react-cookie';
import Loading from "../../components/Loading";
import ReactPixel from 'react-facebook-pixel';

class StripeBookingSuccess extends React.Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        const { cookies } = props;
        this.state = {
            subscriptions: [],
            hasSubscription: false,
            subURL: "",
            successSub: false,
            bookingNumber: false,
            hasGiftCard: false,
            giftCardDetails: null,
            giftCardNumber: false,
            error:  false,
            errorMessage: "",
            loading: true,
            prepaidCard: null,
            subscriptionActive: false, 
            subLoading: false, 

        }
        this.updateBookingCart = this.updateBookingCart.bind(this);
        this.createGiftCard = this.createGiftCard.bind(this);
        this.createPrepaidCard = this.createPrepaidCard.bind(this);
        this.updateUserSubscription = this.updateUserSubscription.bind(this);
    }

    async updateBookingCart(){
        const { cookies } = this.props;
        const queryParams = new URLSearchParams(window.location.search);
        const bookingKey = queryParams.get('bookingKey');
        const prepaid = queryParams.get('prepaid');
        const session_id = queryParams.get('session_id');
        const hasSubscription = queryParams.get('choseSubscription');

        let data = {
            bookingKey: bookingKey,
            session_id: session_id,
        }
        if(bookingKey){
            let res = await api("booking/confirmed", 'POST', data);
            if(res.status == 200){
                if(res.data.success){
                    this.props.fbEvent("Purchase", {currency: "AUD", value: Number(res.data.booking.cart.sessionData.estimatedTotal)})
                    googleEvent(this.props.ReactGA, this.props.GTM, "booking_confirmed", {bookingNumber: res.data.bookingNumber});
                    let hasGiftCard = cookies.get("giftCardDetails") ? true : res.data.giftCardActive ? res.data.giftCardActive : false;
                    if(hasSubscription == true){
                        this.setState({
                            hasSubscription: true,
                            subLoading: true
                        });
                        this.createSubCard(data);
                    }
                    this.setState({
                        bookingNumber: res.data.bookingNumber,
                        hasGiftCard: hasGiftCard,
                        giftCardDetails: cookies.get("giftCardDetails") ? cookies.get("giftCardDetails") : res.data.giftCardDetails ? res.data.giftCardDetails : null,
                        subURL: cookies.get("subURL") ? cookies.get("subURL") : false,
                        loading: false
                    })
                    if(cookies.get("giftCardDetails")){
                        console.log("sGift card cookie set ");
                    } else {
                        if(res.data.giftCardDetails){
                            cookies.set("giftCardDetails", JSON.stringify(res.data.giftCardDetails), { path: '/' , maxAge: 86400  });
                        }
                    }

                    cookies.set("bookingNumber", res.data.bookingNumber, { path: '/' , maxAge: 86400  });

                    if(hasGiftCard){
                        this.createGiftCard(res.data.bookingNumber);
                    }

                    cookies.remove("cartId", { path: '/' , maxAge: 86400  });
                    cookies.remove("confirmStep", { path: '/' , maxAge: 86400  });
                    cookies.remove("extrasStep", { path: '/' , maxAge: 86400  });
                    cookies.remove("detailsStep", { path: '/' , maxAge: 86400  });
                    cookies.remove("serviceStep", { path: '/' , maxAge: 86400  });
                } else {
                    this.setState({
                       error: true,
                       errorMessage: res.data.message,
                       loading: false
                    })
                }
            }
        } else if (prepaid){
            const rego = queryParams.get('rego');
            this.createPrepaidCard(rego)
        } else {
            this.setState({
                error: true,
                errorMessage: "You can only access this page from stripe.",
                loading: false
            })
        }
    }
    
    async createGiftCard(bookingKey){
        const { cookies } = this.props;
        cookies.remove("subURL", { path: '/' , maxAge: 86400  });

        let data = cookies.get("giftCardDetails");
        data.bookingKey = bookingKey ? bookingKey : cookies.get("bookingNumber");

        let res = await api("booking/giftcard/create", 'POST', data);
        let giftCard= res.data.giftCard ? res.data.giftCard : res.data.giftCardNumber;

        if(res.status == 200){
            if(res.data.success){
                googleEvent(this.props.ReactGA, this.props.GTM, "gift_card_created", {giftCardId: giftCard.CardKey});
                this.setState({
                    giftCardNumber: giftCard.CardCode,
                });
                data.giftCardCode = giftCard.CardCode;
                let giftRes = await api("customer/giftcard/send", 'POST', data);
                console.log("giftCard res: ", giftRes.data);

                cookies.set("giftCardNumber", giftCard.CardCode, { path: '/' , maxAge: 86400  });
                cookies.remove("giftCardDetails", { path: '/' , maxAge: 86400  });
                cookies.remove("subURL", { path: '/' , maxAge: 86400  });
                this.setState({
                    giftCardDetails: null,
                    subURL: ""
                })
            } else {
                alert(res.data.message)
            }
        }
    }   
    
    async createPrepaidCard(rego){
        const { cookies } = this.props;
        const queryParams = new URLSearchParams(window.location.search);
        const cardId = queryParams.get('prepaidid');
        const session_id = queryParams.get('session_id');
        const email = queryParams.get('email');
        try {
            let res = await api("prepaid/purchasesuccess", 'POST', {
                rego: rego,
                cardId: cardId,
                session_id: session_id,
                email: email
            });
    
            console.log(res);

            if(res.status == 200){
                if(res.data.success){
                    let card = res.data.card.Card;
                    this.setState({
                        prepaidCard: card.CardCode[0],
                        loading: false
                    })
                } else {
                    this.setState({
                        error: true,
                        errorMessage: "Could not complete purchase of prepaid card. Please contact us to finalise.",
                        loading: false
                    })
                }
            }

        } catch (error) {
            this.setState({
                error: true,
                errorMessage: "Could not complete purchase of prepaid card. Please contact us to finalise.",
                loading: false
            })
        }
    }  
    
    async createSubCard(data){
        let res = await api("booking/subscriptioncard/create", 'POST', data);
        if(res.status == 200){
            if(res.data.success){
                var subs = [];
                for (let sc = 0; sc < res.data.cardIds.length; sc++) {
                    const element = res.data.cardIds[sc];
                    subs.push(element);
                    googleEvent(this.props.ReactGA, this.props.GTM, "subscription_card_created", {subscriptionId: element});
                }

                if(res.data.cardIds.length > 0){
                    this.setState({
                        subscriptionActive: true,
                        subscriptions: subs,
                        subLoading: false
                    });
                }
            }
        }
    }
    
    async updateUserSubscription(selectedSubs, session_id){
        const { cookies } = this.props;
        let bookingKey = cookies.get("bookingNumber");
        let data = {
            bookingKey: bookingKey,
            subscriptions: selectedSubs,
            session_id: session_id
        }

        let res = await api("customer/subscription/create", 'POST', data);
        if(res.status == 200){
            let resp = res.data;
            if(resp.success){
                cookies.remove("selectedSubs", { path: '/' , maxAge: 86400  });
                googleEvent(this.props.ReactGA, this.props.GTM, "subscription_added_customer", {subscriptionId: resp.data.stripeId});
            } else {
                alert(resp.message)
            }
        }
    }

    setupBeforeUnloadListener = () => {
        const parentState = this;
        window.addEventListener("beforeunload", (ev) => {
            if(parentState.state.loading){
                ev.preventDefault();
                return ev.returnValue = 'Are you sure you want to close? Your booking has not been processed yet and you may forfeit your $2 booking fee.';
            } else {
                return;
            }
            
        });
    };


    componentDidMount(){
        document.title = 'Booking Success - Concierge Car Wash';
        const { cookies } = this.props;
        const queryParams = new URLSearchParams(window.location.search);
        const hasGiftCard = queryParams.get('purcahseGiftCard');
        // let selectedSubs = cookies.get('selectedSubs');
        // let successSub = queryParams.get('subscription');
        let successGift = queryParams.get('giftcard');
        ReactPixel.pageView();

        // if(successSub && successSub == "true"){
        //     selectedSubs = selectedSubs.split(',');
        //     this.updateUserSubscription(selectedSubs, session_id);
        // }

        // if(hasGiftCard && hasGiftCard == "true"){
        //     if(cookies.get('giftCardDetails')){
        //         this.createGiftCard();
        //     }
        // }

        // if(!cookies.get("subscriptionsActive")){
        //     this.createSubCard();
        // }

        this.updateBookingCart();

        this.setState({
            // successSub:successSub == "true" ? true : false,
            bookingNumber: cookies.get("bookingNumber") ? cookies.get("bookingNumber")  : false,
            giftCardNumber: cookies.get("giftCardNumber") ? cookies.get("giftCardNumber") : false
        })


        this.setupBeforeUnloadListener();
    }

    render(){

        if(this.state.loading){
            return (
                <div className="bookingSuccessPage">
                    {window.innerWidth < 767 && <HeaderMobile/>}
                    <div className="bookingBanner">
                        <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
                    </div>
                    <div className="watingMessage">
                        <h1>
                            We are processing your booking, please do not leave this page until you recieve your booking reference number.
                        </h1>
                    </div>
                    <Loading />
                </div>
            )
        }


        return(
            <div className="bookingSuccessPage">
                {window.innerWidth < 767 && <HeaderMobile/>}
                <div className="bookingBanner">
                    <img src={Images.ccwWhiteLogo} className="bannerLogo" alt="logo" />
                </div>
                {
                    this.state.error ? (
                    <div className="failContent">
                        <h1>{this.state.errorMessage}</h1>
                        <div className="backToWebsite">
                            <a className="lightBlueButton" href={"https://conciergecarwash.com.au"}> Continue </a>
                        </div>
                    </div>
                    ) : (
                        <div className="successContent">
                            <img src={Images.blueTick} className="successTick" alt="success tick" />
                            <h1>{ this.state.successSub ? "You successfully subscribed" : this.state.prepaidCard !== null ? "You successfully purchased your prepaid card" : "You successfully confirmed your booking."}</h1>
                            {
                                this.state.bookingNumber !== false && (
                                    <>
                                        <h3 className="bookingNumber">
                                            Booking # {this.state.bookingNumber}
                                        </h3>
                                        <p className="note"><b>Please Note</b></p>
                                        <p className="note">
                                            <b>Pricing:</b> The total price is an estimate, and an exact quote will be given on the day. Prices vary depending on the state, size and condition of your vehicle.
                                        </p>
                                        <p className="note">
                                            <b>Time:</b> We will do our best to facilitate your reservation time; however, at times, delays can occur. The team will inform you of the estimated completion time.
                                        </p>
                                    </>
                                )
                            } 
                            {
                                this.state.prepaidCard !== null && (
                                    <h3 className="bookingNumber">
                                        Prepaid Card # {this.state.prepaidCard}
                                    </h3>
                                )
                            } 
                            {
                                this.state.giftCardNumber !== false && (
                                    <div>
                                        <h3 className="bookingNumber">Gift Card Purchase was successful</h3>
                                        <h4 className="giftCardNumber">An Email was sent to the recipient.</h4>
                                    </div>
                                )
                            }
                            <div className="backToWebsite">
                                <a className="lightBlueButton" href={"https://conciergecarwash.com.au"}> Continue </a>
                            </div>
                            {
                                this.state.hasSubscription && this.state.subLoading && (
                                    <div style={{marginTop: 50}}>
                                        <h4>Your Subscriptions are being created, please wait...</h4>
                                        <Loading />
                                    </div>
                                )
                            }
                            {
                                this.state.subscriptionActive && (
                                    <>
                                        <h2 className="subsctriptionHeading">Your purchased subscriptions: </h2>
                                        <div className="listedSubs">
                                            <ul>
                                                {
                                                    this.state.subscriptions.map(function(sub){
                                                        return (
                                                            <li className={sub}>{sub}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </>
                                )
                            }
                            {/* {
                                this.state.subURL && (
                                    <div className="subscriptionSection">
                                        <h2 className="subsctriptionHeading">Continue to purchase your selected subscriptions and/or gift card: </h2>
                                        <div className="listedSubs">
                                            <ul>
                                                {
                                                    this.state.subscriptions.map(function(sub){
                                                        return (
                                                            <li className={sub}>{sub}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            this.state.giftCardDetails && (
                                                <div className="subscriptionSection">
                                                    <p className="giftCardText">{`Gift card for: ${this.state.giftCardDetails.firstName} ${this.state.giftCardDetails.lastName}`}</p>
                                                    <p className="giftCardAmount">{`$${this.state.giftCardDetails.amount}`}</p>
                                                </div>
                                            )
                                        }
                                        <a className="lightBlueButton" href={this.state.subURL}> Continue </a>
                                    </div>
                                )
                            } */}
                            {/* {
                                this.state.giftCardDetails && (
                                    <div className="subscriptionSection">
                                        <h2 className="subsctriptionHeading">Continue to purchase your gift card: </h2>
                                        <div className="subscriptionSection">
                                            <p className="giftCardText">{`Gift card for: ${this.state.giftCardDetails.firstName} ${this.state.giftCardDetails.lastName}`}</p>
                                            <p className="giftCardAmount">{`$${this.state.giftCardDetails.amount}`}</p>
                                        </div>
                                        <a className="lightBlueButton" href={this.state.subURL}> Continue </a>
                                    </div>
                                )
                            } */}
                        </div>
                    )
                }
            </div>
        )
    }
}

export default withCookies(StripeBookingSuccess);