import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import "./App.css";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { api } from "./api";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";

import Maintenance from "./views/Maintenance";
import JobBooking from "./views/JobBooking";
import Booking from "./views/Booking";
import StripeBookingSuccess from "./views/Booking/stripeBookingSuccess";
import CanceledSubscription from "./views/Booking/canceledSubscription";
import JobSuccess from "./views/JobBooking/success";
import NotFound from "./views/404";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: null,
      isOffline: false,
      googleInitialise: false,
    };
    this.checkHealth = this.checkHealth.bind(this);
    this.checkOnlineStatus = this.checkOnlineStatus.bind(this);
    this.fbEvent = this.fbEvent.bind(this);
  }

  async checkHealth() {
    try {
      let health = await axios.get(
        "https://api.conciergecarwash.com.au/health"
      );
      console.log(health);
      if (health.status !== 200) {
        this.setState({
          isOffline: true,
        });
      }
    } catch (error) {
      this.setState({
        isOffline: true,
      });
    }
  }

  async checkOnlineStatus() {
    try {
      let health = await axios.get(
        "https://api.conciergecarwash.com.au/v1/maintenance/status"
      );
      console.log("check health: ", health);
      if (health.data.isOnline) {
        this.setState({
          isOffline: false,
        });
      } else {
        this.setState({
          isOffline: true,
        });
      }
    } catch (error) {
      this.setState({
        isOffline: true,
      });
    }
  }

  componentDidMount() {
    this.checkHealth();
    this.checkOnlineStatus();
    // if (this.state.googleInitialise == false) {
    //   const tagManagerArgs = {
    //     gtmId: "GTM-WNGMZTK",
    //   };
    //   TagManager.initialize(tagManagerArgs);
    //   ReactPixel.init("323158478178364", {}, options);
    //   this.setState({
    //     googleInitialise: true,
    //   });
    // }
    // ReactPixel.pageView();
  }

  fbEvent = (event, data) => {
    // ReactPixel.track(event, data);
  };

  render() {
    // ReactGA.initialize("G-PM2TM03CWD");
    // ReactGA.send({ hitType: "pageview", page: "/" });
    // For tracking page view
    return (
      <Router>
        <Routes>
          {this.state.isOffline && (
            <Route
              exact
              path="/"
              element={<Maintenance ReactGA={ReactGA} GTM={TagManager} />}
            />
          )}
          <Route
            exact
            path="/"
            element={<JobBooking ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/job-success"
            element={<JobSuccess ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  }
}
export default App;
