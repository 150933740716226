import React from "react";
import "../style.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api, carRegoDetails } from "../../../api";
import { CardOption, ToggleSwitch, PricingCard } from "../../../components";
import { Button } from "@mui/material";
import VehicleDetails from "../../../components/vehicleDetailsModal";
import Loading from "../../../components/Loading";

class NoBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carState: "NSW",
      carRego: null,
      carDetails: null,
      displayModal: false,
      isLoading: false,
    };
    this.handleCarRego = this.handleCarRego.bind(this);
    this.getVehicleDetails = this.getVehicleDetails.bind(this);
    this.continueToNextStep = this.continueToNextStep.bind(this);
    this.handleModalDisplay = this.handleModalDisplay.bind(this);
    this.setVehicleDetails = this.setVehicleDetails.bind(this);
    this.handleVehicleDetails = this.handleVehicleDetails.bind(this);
  }

  handleCarRego = (event) => {
    const { value } = event.target;
    this.setState({ carRego: value });
  };

  getVehicleDetails = async () => {
    let rego = this.state.carRego;
    let state = this.state.carState;
    if (rego !== "" && (state !== null || state !== "")) {
      let vehicleDetails = await carRegoDetails(state, rego);
      if (vehicleDetails.status == 200) {
        if (vehicleDetails.data.data.nevdisPlateSearch_v2.length > 0) {
          let details = vehicleDetails.data.data.nevdisPlateSearch_v2[0];
          console.log("vehicleDetails: ", details);
          this.setState({
            isLoading: false,
            carDetails: details,
            displayModal: true,
          });
        } else {
          alert(
            "No Matching Rego for selected state, please make sure you have selected correctly."
          );
          this.setState({
            isLoading: false,
            carDetails: null,
            displayModal: false,
          });
        }
      }
    }
  };

  continueToNextStep = () => {
    if (this.state.carRego == "" || this.state.carRego == null) {
      alert("Cannot continue without entering a car rego.");
      return;
    }

    this.setState({
      isLoading: true,
    });
    this.getVehicleDetails();
  };

  handleVehicleDetails(key, value) {
    let vehicleDetails = this.state.carDetails;
    vehicleDetails[key] = value;
    this.setState({
      carDetails: vehicleDetails,
    });
  }

  setVehicleDetails(finalCarDetails) {
    if (finalCarDetails !== null) {
      this.props.updateVehicleDetails(finalCarDetails);
      console.log("finalCarDetails: ", finalCarDetails);
    } else {
      alert("Please confirm details before continuing.");
    }
  }

  handleModalDisplay = () => {
    this.setState({
      displayModal: !this.state.displayModal,
    });
  };

  render() {
    return (
      <div className="jobBookingFlow">
        <div className="flexColumn">
          <div className="oneColumn">
            <label>Vehicle Rego</label>
            <input
              className="jobInput"
              type={"text"}
              name={"carRego"}
              placeholder={"Enter rego..."}
              id={"carRego"}
              onChange={this.handleCarRego}
              value={this.state.carRego}
            />
          </div>
          <div className="oneColumn">
            <label>State of registration: </label>
            <select
              className="jobInput"
              name={"carState"}
              placeholder={"Enter state..."}
              id={"carState"}
              onChange={(event) =>
                this.setState({
                  carState: event.target.value,
                })
              }
              value={this.state.carState}
            >
              {" "}
              <option value="NSW">NSW</option>
              <option value="NT">NT</option>
              <option value="VIC">VIC</option>
              <option value="QLD">QLD</option>
              <option value="ACT">ACT</option>
              <option value="SA">SA</option>
              <option value="WA">WA</option>
              <option value="TAS">TAS</option>
            </select>
          </div>
          <VehicleDetails
            carDetails={this.state.carDetails}
            displayModal={this.state.displayModal}
            handleModalDisplay={this.handleModalDisplay}
            setVehicleDetails={this.setVehicleDetails}
            handleVehicleDetails={this.handleVehicleDetails}
          />
        </div>
        <div className="buttonSection">
          <button
            className="prevButton"
            onClick={() => this.props.goBack("location")}
          >
            Previous Step
          </button>
          <Button
            onClick={() => this.continueToNextStep()}
            title="Retrieve Vehicle Information"
            color="primary"
            className="getCarDetails"
          >
            <span>Next Step</span>
          </Button>
        </div>

        {this.state.isLoading && (
          <div className="absolute">
            <div className="optionBox">
              <Loading />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NoBooking;
