import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { api } from "../../api";
import ExtraItem from "../ExtraItem";
import SubscriptionItem from "../SubscriptionItem";
import * as Images from "../../themes/images";
import NewServiceModal from "../newServiceModal";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class Extra extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            extraArray: [],
            subscriptionArray: [],
            giftCardDetails: {},
            giftCardAmount: 0,
            gEvent: null,
            giftCardActive : null || this.props.giftCardDetails && this.props.giftCardDetails.amount && true,
            locationExtras: [],
            extraServiceModalVisible: false,
            termsModalDetails: "",
            termsModalDisplay: false
        }
        this.getAllExtra = this.getAllExtra.bind(this);
        this.selectExtra = this.selectExtra.bind(this);        
        this.handleGiftCardInputs = this.handleGiftCardInputs.bind(this);        
        this.handleGiftCardActive = this.handleGiftCardActive.bind(this);        
        this.handleNewServiceModal = this.handleNewServiceModal.bind(this);        
        this.termsModal = this.termsModal.bind(this);        
    }

    async getAllExtra(){
        let res = await api(`extra?locationId=${this.props.selectLocation._id}`, 'GET', null);
        if(res.status === 200){
            if(res.data.success){
                console.log("Extras: ", res.data);
                this.setState({
                    extraArray: res.data.extras,
                    subscriptionArray: res.data.subscriptions,
                    locationExtras: res.data.locationExtras,
                });
                if(res.data.locationExtras.length > 0){
                    let showModal = true;
                    for (let index = 0; index < this.props.selectedExtrasNames.length; index++) {
                        const element = this.props.selectedExtrasNames[index];
                        if(element == res.data.locationExtras[0].name){
                            showModal = false;
                        }
                    }
                    if(showModal){
                        this.setState({
                            extraServiceModalVisible: true
                        })
                    }
                    
                }
            }
        } else {
            console.log(res);
        }
    }

    selectExtra(extra){
        let selectedExtras = this.props.selectedExtras;
        let selectedExtrasNames = this.props.selectedExtrasNames;

        if(selectedExtrasNames.includes(extra.name)){
            const index = selectedExtrasNames.indexOf(extra.name);
            if(index > -1){
                selectedExtrasNames.splice(index, 1);
                var secondIndex = selectedExtras.findIndex(function(o){
                    return o.name === extra.name;
                })
                if (secondIndex !== -1) selectedExtras.splice(secondIndex, 1);
            }
        } else {
            selectedExtrasNames.push(extra.name);
            selectedExtras.push(extra);
        }
        this.props.handleSelectedExtras(selectedExtras, selectedExtrasNames);
    }

    selectSubscription(sub){
        let selectedSubscriptions = this.props.selectedSubscriptions;
        let selectedSubscriptionNames = this.props.selectedSubscriptionNames;
        let checkedSubs = this.props.subscriptionsChecked;

        if(selectedSubscriptionNames.includes(sub.name)){
            const index = selectedSubscriptionNames.indexOf(sub.name);
            if(index > -1){
                selectedSubscriptionNames.splice(index, 1);
                var secondIndex = selectedSubscriptions.findIndex(function(o){
                    return o.name === sub.name;
                })
                if (secondIndex !== -1) selectedSubscriptions.splice(secondIndex, 1);

            }
        } else {
            selectedSubscriptionNames.push(sub.name);
            selectedSubscriptions.push(sub);
        }
        this.props.handleSelectedSubscriptions(selectedSubscriptions, selectedSubscriptionNames);

        if(checkedSubs.includes(sub.name)){
            const index = checkedSubs.indexOf(sub.name);
            if(index > -1){
                checkedSubs.splice(index, 1);
            }
        }
        this.setState({
            subscriptionsChecked: checkedSubs
        })
    }

    handleGiftCardInputs(val){
        val = val.target;
        clearTimeout(this.state.gEvent);
        this.setState(prevState => {
            let giftCardDetails = Object.assign({}, prevState.giftCardDetails);  // creating copy of state variable jasper
            giftCardDetails[val.name] = val.value;                     // update the name property, assign a new value                 
            return { giftCardDetails };    
        });
        if(val.name == "amount"){
            if(val.value !== ""){
                this.setState({
                    giftCardAmount: val.value
                })
            }

            let gEvent = setTimeout(() => {
                this.props.googleEvent(this.props.ReactGA, this.props.GTM, "user_booking_gift_card", {
                    firstName: this.state.giftCardDetails.firstName,
                    lastName: this.state.giftCardDetails.lastName,
                    email: this.state.giftCardDetails.email,
                    mobile: this.state.giftCardDetails.mobile,
                    amount: this.state.giftCardDetails.amount,
                });
            }, 2000);
            
            this.setState({
                gEvent: gEvent
            })
            
        }
        setTimeout(() => {
            this.props.handleGiftCardDetails(this.state.giftCardDetails);
        }, 300);
    }
    
    handleGiftCardActive(){
        this.setState({
            giftCardActive: !this.state.giftCardActive
        })
        this.props.handleGiftCardActive(!this.state.giftCardActive);
    }   
    
    handleNewServiceModal(){
        this.setState({
            extraServiceModalVisible: false
        })
    }

    termsModal(details, show){
        console.log("details: ", details);
        console.log("show: ", show);
        this.setState({
            termsModalDetails: details,
            termsModalDisplay: show
        })
    }

    validateEmail(val){
        val = val.target;
        let email = val.value;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return (true)
        }
        alert("You have entered an invalid email address!")
        
        return (false)
    }

    componentDidMount(){
        this.getAllExtra();
        this.setState({
            giftCardDetails: this.props.giftCardDetails,
            giftCardActive: this.props.giftCardActive
        })
    }
    

    render(){
        let parentThis = this;        
        return(
            <div className="extraStep">
                <h2>Select your extras</h2>
                {
                    this.state.extraArray.length > 0 && 
                    this.state.extraArray.map(function(extra, i){
                        return (
                            <ExtraItem 
                                index={i}
                                extra={extra} 
                                selectExtra={() => parentThis.selectExtra(extra)} 
                                selectedExtrasNames={parentThis.props.selectedExtrasNames}
                            />
                        )
                    })
                }
                <h2 className="notFirstHeading">Subscription</h2>
                {
                    this.state.subscriptionArray.length > 0 && 
                    this.state.subscriptionArray.map(function(sub, i){
                        return (
                            <SubscriptionItem 
                                key={i}
                                sub={sub} 
                                subscriptionsChecked={parentThis.props.subscriptionsChecked} 
                                checkSubscription={(name) => parentThis.props.checkSubscription(name)} 
                                selectSubscription={() => parentThis.selectSubscription(sub)} 
                                selectedSubscriptionNames={parentThis.props.selectedSubscriptionNames}
                                termsModal={(details, display) => parentThis.termsModal(details, display)}
                            />
                        )
                    })
                }
                <h2 className="notFirstHeading">Gift Card</h2>
                <div className="giftCardSection">
                    <img 
                        className={"giftCardCheckBox " + (this.state.giftCardActive ? "" : "unchecked")} 
                        src={this.state.giftCardActive ? Images.blueTick : Images.unChecked} 
                        alt="toggle" 
                        onClick={() => this.handleGiftCardActive()}
                    />
                    <div>
                        <h5 className="giftCardHeading" onClick={() => this.handleGiftCardActive()}>Gift Card</h5>
                        <p className="giftCardDesc">There is nothing better than a sparkiling clean car. Pass on the gift today!</p>
                        {
                            this.state.giftCardActive && (
                            <div className="giftCardForm">
                                <div className="flexRow">
                                    <div className="twoColumn">
                                        <input 
                                            type={"text"} 
                                            name={"firstName"} 
                                            placeholder={"First name"}
                                            id={"giftCardFirstName"} 
                                            onChange={this.handleGiftCardInputs} 
                                            value={this.state.giftCardDetails && this.state.giftCardDetails.firstName} 
                                        />
                                    </div>
                                    <div className="twoColumn">
                                        <input 
                                            type={"text"} 
                                            name={"lastName"} 
                                            placeholder={"Last name"}
                                            id={"giftCardLastName"}
                                            onChange={this.handleGiftCardInputs} 
                                            value={this.state.giftCardDetails && this.state.giftCardDetails.lastName}
                                        />
                                    </div>
                                </div>
                                <div className="flexRow">
                                    <div className="oneColumn">
                                        <input 
                                            type={"email"} 
                                            name={"email"} 
                                            placeholder={"Email address"}
                                            id={"giftCardEmail"} 
                                            onChange={this.handleGiftCardInputs} 
                                            onBlur={this.validateEmail}
                                            value={this.state.giftCardDetails && this.state.giftCardDetails.email}
                                        />
                                    </div>
                                </div>
                                <div className="flexRow">
                                    <div className="oneColumn">
                                        <input 
                                            type={"text"} 
                                            name={"mobile"} 
                                            placeholder={"Mobile"}
                                            id={"giftCardMobile"} 
                                            onChange={this.handleGiftCardInputs} 
                                            value={this.state.giftCardDetails && this.state.giftCardDetails.mobile}
                                        />
                                    </div>
                                </div>
                                <div className="giftCardAmountSection">
                                    <p className="giftCardAmount">Amount</p>
                                    <div className="giftCardAmountInput">
                                        <p className="amountDollar">$</p>
                                        <input 
                                            type={"number"} 
                                            name={"amount"} 
                                            id={"giftCardAmount"} 
                                            onChange={this.handleGiftCardInputs} 
                                            value={this.state.giftCardDetails && this.state.giftCardDetails.amount} 
                                        />
                                    </div>
                                    
                                </div>
                            </div> 
                            )
                        }
                        
                    </div>
                </div>
                {
                    this.state.extraServiceModalVisible && (
                        <NewServiceModal 
                            service={this.state.locationExtras[0]} 
                            location={this.props.selectedLocationName} 
                            handleNewServiceModal={() => this.handleNewServiceModal()}
                            addNewService={() => {
                                this.selectExtra(this.state.locationExtras[0]);
                                this.handleNewServiceModal()
                            }}
                        />
                    )
                }
                <Modal show={this.state.termsModalDisplay} onHide={() => {this.setState({termsModalDisplay: false})}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Subscription Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                        {
                            this.state.termsModalDetails && this.state.termsModalDetails.length > 0 && this.state.termsModalDetails.map(function(item, i){
                                return <li key={i}>{item}</li>
                            })
                        }
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => {this.setState({termsModalDisplay: false})}}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Extra;